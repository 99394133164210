import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import ChemicalElementSquare from './ChemicalElementSquare'
import findElement from './findElement'
import mapCapitalLetters from '../mapCapitalLetters'

export default function PreviewTitle({
  id,
  title,
}) {
  const { titlePart1, titlePart2, element } = useMemo(() => {
    const { matchedIndex, element: foundElement } = findElement(title)
    return {
      titlePart1: title.slice(0, matchedIndex),
      titlePart2: title.slice(matchedIndex + foundElement.symbol.length),
      element: foundElement,
    }
  }, [title])

  const isTitleNotEmpty = title !== ''

  return (
    <div id={id} className="preview-title">
      <div className="text">{ mapCapitalLetters(titlePart1) }</div>
      {isTitleNotEmpty && (
        <ChemicalElementSquare
          name={element.name}
          code={element.symbol}
          atomicNumber={element.atomicNumber}
        />
      )}
      <div className="text">{ mapCapitalLetters(titlePart2) }</div>
      <style jsx>
        {`
        .preview-title {
          display: inline-block;
          position: relative; // This is important, otherwise the smoke will appear on top of the texts
          user-select: none;

          > .text {
            display: inline-block;
            font-family:BreakingCooper;
            font-weight: 700;
            font-size:calc(110px * var(--preview-size-factor));
            line-height: 1em;
            letter-spacing: 0.025em; /* 2px if font size is 76px */

            > :global(.capital) {
              font-weight: 500;
            }
          }
        }
        `}
      </style>
    </div>
  )
}

PreviewTitle.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
