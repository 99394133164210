import React, { useMemo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import useDimensions from 'react-use-dimensions'
import classnames from 'classnames'

import PreviewTitle from './PreviewTitle'
import renderSmoke from './renderSmoke'
import useNavigator from '../useNavigator'

const setPreviewSizeFactor = (element, value) => {
  element.style.setProperty('--preview-size-factor', value)
}

const getPreviewSizeFactor = (element) => (
  parseFloat(element.style.getPropertyValue('--preview-size-factor'))
)

const getScaleDownFactor = (clientWidth) => {
  if (clientWidth < 500) {
    return 1
  }

  if (clientWidth < 700) {
    return 0.9
  }

  return 0.7
}

export default function IntroPreview({
  title1 = 'Breaking',
  title2 = 'Bad',
  isStatic = false,
  style = {},
}) {
  const [introPreviewDimensionsRef, { width }] = useDimensions()
  const introPreviewRef = useRef(null)
  const margin1Ref = useRef(null)
  const margin2Ref = useRef(null)
  const { isSafari } = useNavigator()

  useEffect(() => {
    const introPreview = introPreviewRef.current
    const margin1 = margin1Ref.current
    const margin2 = margin2Ref.current
    const chemicalElement1 = introPreview?.querySelector('#preview-title-1 .chemical-element')
    const chemicalElement2 = introPreview?.querySelector('#preview-title-2 .chemical-element')

    setPreviewSizeFactor(introPreview, 0.7)
    const previewSizeFactor = getPreviewSizeFactor(introPreview)

    if (
      !introPreview
        || !margin1
        || !margin2
    ) {
      return () => {}
    }

    introPreview.style.opacity = 0

    margin1.style.marginLeft = 0
    margin2.style.marginLeft = 0

    const isAtLeastOneElementEmpty = !chemicalElement1 || !chemicalElement2

    const margin = isAtLeastOneElementEmpty ? 0 : (
      chemicalElement1.getBoundingClientRect().left
      + chemicalElement1.getBoundingClientRect().width
      - chemicalElement2.getBoundingClientRect().left
    ) / previewSizeFactor

    if (margin > 0) {
      margin2.style.marginLeft = `calc(${margin}px * var(--preview-size-factor))`
    } else {
      margin1.style.marginLeft = `calc(${-margin}px * var(--preview-size-factor))`
    }

    const resizeFactor = introPreview.clientWidth * getScaleDownFactor(introPreview.clientWidth)
    const adjustedSizeFactor = (resizeFactor) / 800
    setPreviewSizeFactor(introPreview, adjustedSizeFactor)

    introPreview.style.opacity = 1

    const stopSmoke = renderSmoke(introPreview, isStatic)

    return () => {
      if (stopSmoke) {
        stopSmoke()
      }
    }
  }, [title1, title2, width])

  const canvasKey = useMemo(() => `${title1}-${title2}`, [title1, title2])

  const isAtLeastOneTitleEmpty = useMemo(() => (
    title1 === '' || title2 === ''
  ), [title1, title2])

  return (
    <section
      ref={(node) => {
        introPreviewRef.current = node
        introPreviewDimensionsRef(node)
      }}
      className={classnames('intro-preview', { '-safari': isSafari })}
      style={style}
    >
      <div className="smoke-wrapper">
        <canvas
          className="smoke"
          key={canvasKey}
        />
      </div>
      <div className="titles">
        <div ref={margin1Ref} style={{ display: 'inline-block' }} />
        <PreviewTitle id="preview-title-1" title={title1} />
        {!isAtLeastOneTitleEmpty && (<br />)}
        <div ref={margin2Ref} style={{ display: 'inline-block' }} />
        <PreviewTitle id="preview-title-2" title={title2} />
      </div>
      <div className="watermark">
        KasselLabs.io
      </div>
      <style jsx>
        {`
        .intro-preview {
          background: #15220c;
          border: 2px solid #29773E88;
          border-radius: 6px;
          padding: 1em;
          width: calc(min(800px, 90vw));
          aspect-ratio: 16 / 9;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          &.-safari {
            height: ${width * (9 / 16)}px;
          }

          .titles {
            white-space: nowrap;
          }

          .smoke-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
          }

          .watermark {
            position: absolute;
            right: 0.4em;
            bottom: 0;
            font-size: 26px;
            opacity: 0.5;
            overflow: hidden;
            font-family: 'MontserratAlternates', sans-serif;
            user-select: none;
            pointer-events: none;

            @media screen and (max-width: 768px) {
              font-size: 18px;
            }

            @media screen and (max-width: 400px) {
              font-size: 15px;
            }
          }
        }
        `}
      </style>
    </section>
  )
}

IntroPreview.propTypes = {
  title1: PropTypes.string.isRequired,
  title2: PropTypes.string.isRequired,
  isStatic: PropTypes.bool,
  style: PropTypes.object,
}
