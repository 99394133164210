import React from 'react'

const mapCapitalLetters = (string) => (
  <>
    {string.split('').map((letter, index) => {
      if (letter === letter.toUpperCase()) {
        // eslint-disable-next-line react/no-array-index-key
        return <span key={index} className="capital">{letter}</span>
      }

      return letter
    })}
  </>
)

export default mapCapitalLetters
