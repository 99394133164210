import periodicTable from 'periodic-table'

const shouldChange = (candidate, currentFoundElement, title) => {
  if (!currentFoundElement) {
    return true
  }
  const { element: currentElement, matchedIndex: currentIndex } = currentFoundElement

  const candidateIndex = title.indexOf(candidate.toLowerCase())

  // First try with whatever appears first, then try it with whatever is larger
  return (
    candidateIndex < currentIndex
    || (
      candidateIndex === currentIndex
      && candidate.length > currentElement.symbol.length
    )
  )
}

const findElement = (title) => {
  const sanitizedTitle = title.toLowerCase()

  let foundElement = null
  Object.entries(periodicTable.symbols).forEach(([symbol, element]) => {
    const sanitizedSymbol = symbol.toLowerCase()
    const matchedIndex = sanitizedTitle.indexOf(sanitizedSymbol)

    if (matchedIndex > -1 && shouldChange(symbol, foundElement, sanitizedTitle)) {
      foundElement = {
        matchedIndex,
        element,
      }
    }
  })

  return foundElement || {
    matchedIndex: 0,
    element: {
      ...periodicTable.elements.Hydrogen,
      symbol: sanitizedTitle.slice(0, 2),
      name: 'Nothing',
    },
  }
}

export default findElement
