import React from 'react'
import PropTypes from 'prop-types'

export default function ChemicalElementSquare({
  name,
  code,
  atomicNumber,
}) {
  return (
    <span className="chemical-element">
      <div>{ code }</div>
      <div className="name">{ name }</div>
      <div className="atomic-number">{ atomicNumber }</div>
      <style jsx>
        {`
        .chemical-element {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: calc(165px * var(--preview-size-factor));
          height: calc(165px * var(--preview-size-factor));
          position: relative;
          font: 700 calc(95px * var(--preview-size-factor)) Arial;
          color: #fafffb;
          background: #417d67;
          background: linear-gradient(to right, #417d67, #091813);
          border: calc(3px * var(--preview-size-factor)) solid #ddffe2;
          margin: 0 calc(10px * var(--preview-size-factor));

          .name {
            position: absolute;
            font-size: calc(24px * var(--preview-size-factor));
            bottom: calc(10px * var(--preview-size-factor));
            left: 50%;
            transform: translateX(-50%);
            font-weight: 400;
            font-family: ArialNarrow, sans-serif;
          }

          .atomic-number {
            position: absolute;
            font-size: calc(26px * var(--preview-size-factor));
            top: calc(8px * var(--preview-size-factor));
            left: 50%;
            transform: translateX(-50%);
            font-weight: 400;
          }
        }
        `}
      </style>
    </span>
  )
}

ChemicalElementSquare.propTypes = {
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  atomicNumber: PropTypes.number.isRequired,
}
