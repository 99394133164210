// Code Migrated from the old project
/* eslint-disable camelcase */

function Particle({
  canvas,
  dirtyLeft,
  dirtyTop,
  dirtyBottom,
  dirtyRight,
  windVelocity = 0.01,
}) {
  this.canvas = canvas
  this.dirtyLeft = dirtyLeft
  this.dirtyRight = dirtyRight
  this.dirtyTop = dirtyTop
  this.dirtyBottom = dirtyBottom
  this.windVelocity = windVelocity

  this.m_x = null
  this.m_y = null
  this.m_age = null
  this.m_xVector = null
  this.m_yVector = null
  this.m_scale = null
  this.m_alpha = null
  this.m_canRegen = null
  this.m_timeDie = null
  this.m_emitter = null

  this.init = function thisInit(emitter, age) {
    this.m_age = age
    this.m_emitter = emitter
    this.m_canRegen = true
    this.startRand()
  }

  this.isAlive = function thisIsAlive() {
    return this.m_age < this.m_timeDie
  }

  this.startRand = function thisStartRand() {
    // smoke rises and spreads
    this.m_xVector = Math.random() * 0.5 - 0.25
    this.m_yVector = -1.5 - Math.random()
    this.m_timeDie = 20000 + Math.floor(Math.random() * 12000)

    const invDist = 1.0 / Math.sqrt(this.m_xVector * this.m_xVector
                + this.m_yVector * this.m_yVector)
    // normalise speed
    this.m_xVector = this.m_xVector * invDist * this.m_emitter.m_speed
    this.m_yVector = this.m_yVector * invDist * this.m_emitter.m_speed
    // starting position within a 20 pixel area
    this.m_x = (this.m_emitter.m_x + Math.floor(Math.random() * 20) - 10)
    this.m_y = (this.m_emitter.m_y + Math.floor(Math.random() * 20) - 10)
    // the initial age may be > 0. This is so there is already a smoke trail
    // in
    // place at the start
    this.m_x += (this.m_xVector + this.windVelocity) * this.m_age
    this.m_y += this.m_yVector * this.m_age
    this.m_scale = 0.01
    this.m_alpha = 0.0
  }

  this.update = function thisUpdate(timeElapsed) {
    this.m_age += timeElapsed
    if (!this.isAlive()) {
      // smoke eventually dies
      if (Math.random() > this.m_emitter?.m_dieRate) {
        this.m_canRegen = false
      }
      if (!this.m_canRegen) {
        return
      }
      // regenerate
      this.m_age = 0
      this.startRand()
      return
    }
    // At start the particle fades in and expands rapidly (like in real
    // life)
    const fadeIn = this.m_timeDie * 0.05
    let startScale
    const maxStartScale = 0.3
    if (this.m_age < fadeIn) {
      this.m_alpha = this.m_age / fadeIn
      startScale = this.m_alpha * maxStartScale
      // y increases quicker because particle is expanding quicker
      this.m_y += this.m_yVector * 2.0 * timeElapsed
    } else {
      this.m_alpha = 1.0 - (this.m_age - fadeIn)
                    / (this.m_timeDie - fadeIn)
      startScale = maxStartScale
      this.m_y += this.m_yVector * timeElapsed
    }
    // the x direction is influenced by wind velocity
    this.m_x += (this.m_xVector + this.windVelocity) * timeElapsed
    this.m_alpha *= this.m_emitter.m_alpha
    this.m_scale = 0.001 + startScale + this.m_age / 4000.0
  }

  this.render = function thisRender(ctx) {
    if (!this.isAlive()) return
    ctx.globalAlpha = this.m_alpha
    const height = this.m_emitter.m_image.height * this.m_scale
    const width = this.m_emitter.m_image.width * this.m_scale
    // round it to a integer to prevent subpixel positioning
    const x = Math.round(this.m_x - width / 2)
    const y = Math.round(this.m_y + height / 2)
    ctx.drawImage(this.m_emitter.m_image, x, y, width, height)
    if (x < dirtyLeft) {
      this.dirtyLeft = x
    }
    if (x + width > dirtyRight) {
      this.dirtyRight = x + width
    }
    if (y < dirtyTop) {
      this.dirtyTop = y
    }
    if (y + height > dirtyBottom) {
      this.dirtyBottom = y + height
    }
  }
}

function ParticleEmitter({
  canvas,
  dirtyLeft,
  dirtyTop,
  dirtyBottom,
  dirtyRight,
  windVelocity = 0.01,
}) {
  this.canvas = canvas

  this.dirtyLeft = dirtyLeft
  this.dirtyRight = dirtyRight
  this.dirtyTop = dirtyTop
  this.dirtyBottom = dirtyBottom
  this.windVelocity = windVelocity

  this.m_x = null
  this.m_y = null
  this.m_dieRate = null
  this.m_image = null
  this.m_speed = 0.04
  this.m_alpha = 1.0

  this.m_listParticle = []

  // ParticleEmitter().init function
  // xScale = number between 0 and 1. 0 = on left side 1 = on top
  // yScale = number between 0 and 1. 0 = on top 1 = on bottom
  // particles = number of particles
  // image = smoke graphic for each particle
  this.init = function thisInit(mAlpha, mSpeed, xScale, yScale, particles, image) {
    this.m_alpha = mAlpha
    this.m_speed = mSpeed

    // the effect is positioned relative to the width and height of the
    // canvas
    this.m_x = this.canvas.width * xScale
    this.m_y = this.canvas.height * yScale
    this.m_image = image
    this.m_dieRate = 0.95
    // start with smoke already in place
    for (let n = 0; n < particles; n += 1) {
      this.m_listParticle.push(new Particle(this))
      this.m_listParticle[n].init(this, n * 50000 * this.m_speed)
    }
  }

  this.update = function thisUpdate(timeElapsed) {
    for (let n = 0; n < this.m_listParticle.length; n += 1) {
      this.m_listParticle[n].update(timeElapsed)
    }
  }

  this.render = function thisRender(thisContext) {
    for (let n = 0; n < this.m_listParticle.length; n += 1) {
      this.m_listParticle[n].render(thisContext)
    }
  }
}

async function getLoadedImage(url) {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => {
      resolve(image)
    }
    image.onerror = reject

    image.src = url
  })
}

function ParticleWorld({
  canvas,
  dirtyLeft,
  dirtyTop,
  dirtyBottom,
  dirtyRight,
  windVelocity = 0.01,
  emitters = [
    ['/images/animation/smoke_1.png', 0.2, 0.05, 0.142, 1.2, 90],
    ['/images/animation/smoke_2.png', 0.3, 0.07, 0.322, 1.3, 90],
    ['/images/animation/smoke_3.png', 0.1, 0.08, 0.622, 1.3, 90],
  ],
}) {
  this.canvas = canvas

  this.dirtyLeft = dirtyLeft
  this.dirtyRight = dirtyRight
  this.dirtyTop = dirtyTop
  this.dirtyBottom = dirtyBottom
  this.windVelocity = windVelocity

  const smokeParams = { ...this }

  this.emitters = emitters.map(() => new ParticleEmitter(smokeParams))

  this.init = async function thisInit() {
    const initPromises = emitters.map(async ([imageURL, ...emitterParams], index) => {
      const image = await getLoadedImage(imageURL)
      this.emitters[index].init(...emitterParams, image)
    })

    await Promise.all(initPromises)
  }

  this.render = async function thisRender(timeElapsed, context) {
    this.emitters.forEach((emitter) => {
      [
        'dirtyLeft',
        'dirtyRight',
        'dirtyTop',
        'dirtyBottom',
        'windVelocity',
      ].forEach((attribute) => {
        // eslint-disable-next-line no-param-reassign
        emitter[attribute] = this[attribute]
      })

      emitter.update(timeElapsed)
      emitter.render(context)
    })
  }
}

function getRenderFunction(context, isStatic) {
  let isInitialized = false
  let stopped = false

  /**
   * Thanks to Ed Welch for his "smoke"-effect
   * http://astronautz.com/wordpress/creating-realistic-particle-effect-with-html5-canvas/
   */
  const smokeWorld = new ParticleWorld({
    canvas: context.canvas,
    dirtyLeft: 0,
    dirtyRight: context.canvas.width,
    dirtyTop: 0,
    dirtyBottom: context.canvas.height,
    windVelocity: 0.01,
  })

  let lastRender = new Date().getTime()
  const render = async () => {
    if (!isInitialized && !isStatic) {
      await smokeWorld.init()
      isInitialized = true
    }
    // time in milliseconds
    const timeElapsed = new Date().getTime() - lastRender
    lastRender = new Date().getTime()
    context.clearRect(
      smokeWorld.dirtyLeft,
      smokeWorld.dirtyTop,
      smokeWorld.dirtyRight - smokeWorld.dirtyLeft,
      smokeWorld.dirtyBottom - smokeWorld.dirtyTop,
    )

    if (!isInitialized && isStatic) {
      const staticBackground = await getLoadedImage('/images/animation/static-background.jpg')
      context.drawImage(staticBackground, 0, 0)
      isInitialized = true
    }

    smokeWorld.render(timeElapsed, context)

    smokeWorld.windVelocity += (Math.random() - 0.5) * 0.002
    if (smokeWorld.windVelocity > 0.015) {
      smokeWorld.windVelocity = 0.015
    }
    if (smokeWorld.windVelocity < 0.0) {
      smokeWorld.windVelocity = 0.0
    }

    if (!stopped && !isStatic) {
      window.requestAnimationFrame(render)
    }

    if (!context.canvas.getAttribute('ready')) {
      context.canvas.setAttribute('ready', true)
    }
  }

  const stop = () => {
    stopped = true
  }

  return [render, stop]
}

function init(container, isStatic) {
  if (!container) {
    return () => {}
  }

  const containerRect = container.getBoundingClientRect()
  const canvas = container.querySelector('.smoke')
  canvas.width = containerRect.width + 200
  canvas.height = containerRect.height + 200

  const context = canvas?.getContext('2d')
  if (!context) {
    return () => {}
  }

  const [render, stop] = getRenderFunction(context, isStatic)
  window.requestAnimationFrame(render)

  return stop
}

module.exports = init
